// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_bg__3tErl{\n    background-color: rgba(0,0,0,0.5);\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.modal_container__3GddM{\n    padding: 20px;\n    background-color: white;\n    border-radius: 13px;\n}\n.modal_btnFlex__1NEx7{\n    margin-top: 20px;\n    display: flex;\n    justify-content: flex-end;\n}\n.modal_btn__2dv1m{\n    background-color: transparent;\n    color: #5D96D8;\n    padding: 0;\n    align-self: center;\n    border: none;\n    margin:0;\n    margin-left: 30px;\n}\n.modal_btn__2dv1m:hover{\n    background-color: transparent;\n    color: black;\n    text-decoration: underline;\n    border: none;\n    }", "",{"version":3,"sources":["webpack://src/components/modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,YAAY;IACZ,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,6BAA6B;IAC7B,cAAc;IACd,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,iBAAiB;AACrB;AACA;IACI,6BAA6B;IAC7B,YAAY;IACZ,0BAA0B;IAC1B,YAAY;IACZ","sourcesContent":[".bg{\n    background-color: rgba(0,0,0,0.5);\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.container{\n    padding: 20px;\n    background-color: white;\n    border-radius: 13px;\n}\n.btnFlex{\n    margin-top: 20px;\n    display: flex;\n    justify-content: flex-end;\n}\n.btn{\n    background-color: transparent;\n    color: #5D96D8;\n    padding: 0;\n    align-self: center;\n    border: none;\n    margin:0;\n    margin-left: 30px;\n}\n.btn:hover{\n    background-color: transparent;\n    color: black;\n    text-decoration: underline;\n    border: none;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "modal_bg__3tErl",
	"container": "modal_container__3GddM",
	"btnFlex": "modal_btnFlex__1NEx7",
	"btn": "modal_btn__2dv1m"
};
export default ___CSS_LOADER_EXPORT___;
