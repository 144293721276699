// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_container__2teO8{\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n}\n.login_loginCard__3ZIw6{\n    padding: 20px;\n  \n    border: 0.5px solid black;\n    border-radius: 10px ;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-row-gap: 10px;\n    row-gap: 10px;\n}\n.login_title__3PXjU{\n    font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/components/login/login.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;;IAEb,yBAAyB;IACzB,oBAAoB;IACpB,aAAa;IACb,0BAA0B;IAC1B,kBAAa;IAAb,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".container{\n    display: flex;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n}\n.loginCard{\n    padding: 20px;\n  \n    border: 0.5px solid black;\n    border-radius: 10px ;\n    display: grid;\n    grid-template-columns: 1fr;\n    row-gap: 10px;\n}\n.title{\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "login_container__2teO8",
	"loginCard": "login_loginCard__3ZIw6",
	"title": "login_title__3PXjU"
};
export default ___CSS_LOADER_EXPORT___;
