// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_generationItem__18nce{\n    display: flex;\n    flex-direction: column;\n    margin-right: 30px;\n    justify-content: space-between;\n    height: 65px;\n}\n.main_historyTitle__x4pr_{\n    border-bottom: 1px solid black;\n    padding-bottom: 10px;\n}\n.main_genButton__1gS5H{\n    height: 34px;\n    margin-top: auto;\n}", "",{"version":3,"sources":["webpack://src/styles/main.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,8BAA8B;IAC9B,oBAAoB;AACxB;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".generationItem{\n    display: flex;\n    flex-direction: column;\n    margin-right: 30px;\n    justify-content: space-between;\n    height: 65px;\n}\n.historyTitle{\n    border-bottom: 1px solid black;\n    padding-bottom: 10px;\n}\n.genButton{\n    height: 34px;\n    margin-top: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generationItem": "main_generationItem__18nce",
	"historyTitle": "main_historyTitle__x4pr_",
	"genButton": "main_genButton__1gS5H"
};
export default ___CSS_LOADER_EXPORT___;
