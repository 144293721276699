// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".winners_filterContainer__20MKx{\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    padding-top: 20px;\n    border-top: 1px solid gray;\n    border-bottom: 1px solid grey;\n}", "",{"version":3,"sources":["webpack://src/styles/winners.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sCAAsC;IACtC,iBAAiB;IACjB,0BAA0B;IAC1B,6BAA6B;AACjC","sourcesContent":[".filterContainer{\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    padding-top: 20px;\n    border-top: 1px solid gray;\n    border-bottom: 1px solid grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "winners_filterContainer__20MKx"
};
export default ___CSS_LOADER_EXPORT___;
