import styled from 'styled-components'

export const Input = styled.input`

border: none;
width: 200px;
margin-right:10px;
`

export const Select = styled.select`

border: none;
width: 200px;
color: grey;
margin-right:10px;
`
