// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app_appGrid__2iZjO{\n    display: grid;\n    grid-template-areas: \n    'layout header'\n    'layout main'\n    ;\n    grid-template-columns: max-content 1fr;\n    grid-template-rows: max-content 1fr;\n    min-height: 100vh;\n}\n.app_layout__1sQuJ{\n    grid-area: layout;\n}\n.app_main__2tulb{\n    grid-area: main;\n    padding: 20px;\n    padding-left: 40px;\n    padding-right: 100px;\n}\n.app_header__2jcQB{\n    grid-area: header;\n}", "",{"version":3,"sources":["webpack://src/styles/app.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;;;IAGA;IACA,sCAAsC;IACtC,mCAAmC;IACnC,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".appGrid{\n    display: grid;\n    grid-template-areas: \n    'layout header'\n    'layout main'\n    ;\n    grid-template-columns: max-content 1fr;\n    grid-template-rows: max-content 1fr;\n    min-height: 100vh;\n}\n.layout{\n    grid-area: layout;\n}\n.main{\n    grid-area: main;\n    padding: 20px;\n    padding-left: 40px;\n    padding-right: 100px;\n}\n.header{\n    grid-area: header;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appGrid": "app_appGrid__2iZjO",
	"layout": "app_layout__1sQuJ",
	"main": "app_main__2tulb",
	"header": "app_header__2jcQB"
};
export default ___CSS_LOADER_EXPORT___;
