// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.bundle_filterContainer__1GKIV{\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    padding-top: 20px;\n    border-top: 1px solid gray;\n    border-bottom: 1px solid grey;\n}\n.bundle_bundleTitle__3AxmF{\n    border-bottom: 1px solid black;\n    padding-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/styles/bundle.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sCAAsC;IACtC,iBAAiB;IACjB,0BAA0B;IAC1B,6BAA6B;AACjC;AACA;IACI,8BAA8B;IAC9B,oBAAoB;AACxB","sourcesContent":["\n.filterContainer{\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    padding-top: 20px;\n    border-top: 1px solid gray;\n    border-bottom: 1px solid grey;\n}\n.bundleTitle{\n    border-bottom: 1px solid black;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "bundle_filterContainer__1GKIV",
	"bundleTitle": "bundle_bundleTitle__3AxmF"
};
export default ___CSS_LOADER_EXPORT___;
