import styled from 'styled-components'

export const MainInput = styled.input`

// width: 149px;
height: 34px;

background: #FFFFFF;
border: 1px solid #3F496C;
box-sizing: border-box;
border-radius: 7px;
`

export const SelectMain = styled.select`

border: 1px solid #3F496C;
background: #FFFFFF;
border-radius: 7px;
height: 34px;
`